var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import { removeCommunityToken, setCommunityToken } from 'src/helpers/token';
import API from '../../../api';
import { LOCAL_STORAGE_KEYS } from '../../../common/localStorageKeys';
import { tryParseJSON } from '../../../common/utils/jsonUtils';
import { localStorageWrapper } from '../../../helpers/storageFactory';
import { createAsyncSlice } from '../sliceCreator';
import { UserRole } from 'src/types/user';
var getTokenFromLocalStorage = function () {
    var token = localStorageWrapper.getItem(LOCAL_STORAGE_KEYS.COMMUNTY_ACCOUNT_TOKEN);
    return token ? tryParseJSON(token) : null;
};
var token = getTokenFromLocalStorage();
var initialState = {
    isAutorized: !!token,
    accountInfo: null,
    isLoading: !!token,
};
var accountSlice = createAsyncSlice({
    name: 'communityAccount',
    initialState: initialState,
    reducers: function (create) { return ({
        register: create.asyncThunk(function (data, _a) {
            var rejectWithValue = _a.rejectWithValue;
            return __awaiter(void 0, void 0, void 0, function () {
                var response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, API.community.account.register(data)];
                        case 1:
                            response = _b.sent();
                            if (response.success) {
                                return [2 /*return*/, response];
                            }
                            return [2 /*return*/, rejectWithValue({ success: false })];
                    }
                });
            });
        }, {
            fulfilled: function (state, action) {
                setCommunityToken(action.payload.data.token);
            },
        }),
        googleAuth: create.asyncThunk(function (data, _a) {
            var rejectWithValue = _a.rejectWithValue;
            return __awaiter(void 0, void 0, void 0, function () {
                var response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, API.community.account.googleAuth(data)];
                        case 1:
                            response = _b.sent();
                            if (response.success) {
                                return [2 /*return*/, response];
                            }
                            return [2 /*return*/, rejectWithValue({ success: false })];
                    }
                });
            });
        }, {
            fulfilled: function (state, action) {
                if (action.payload.success) {
                    setCommunityToken(action.payload.data.token);
                }
            },
        }),
        getAccountInfo: create.asyncThunk(function (data, _a) {
            var rejectWithValue = _a.rejectWithValue;
            return __awaiter(void 0, void 0, void 0, function () {
                var response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, API.community.account.getAccounItnfo(data)];
                        case 1:
                            response = _b.sent();
                            if (response.success) {
                                return [2 /*return*/, response];
                            }
                            return [2 /*return*/, rejectWithValue({ success: false })];
                    }
                });
            });
        }, {
            fulfilled: function (state, action) {
                state.accountInfo = action.payload.data.account;
                state.isLoading = false;
                state.isAutorized =
                    (action.payload.data.account.subjects && action.payload.data.account.subjects.length > 0) ||
                        action.payload.data.account.roleKey === UserRole.UnivercityAdmin;
            },
        }),
        updateCommunityToken: create.reducer(function (state, action) {
            state.isAutorized = true;
            setCommunityToken(action.payload.newAccessToken);
        }),
        communityLogout: create.reducer(function () {
            removeCommunityToken();
            return __assign(__assign({}, initialState), { isLoading: false });
        }),
    }); },
    selectors: {
        selectIsAutorized: function (state) { return state.isAutorized; },
        selectIsLoading: function (state) { return state.isLoading; },
        selectMyId: function (state) { var _a; return (_a = state.accountInfo) === null || _a === void 0 ? void 0 : _a.id; },
        selectMyRole: function (state) { var _a; return (_a = state.accountInfo) === null || _a === void 0 ? void 0 : _a.roleKey; },
    },
});
export var register = (_a = accountSlice.actions, _a.register), getAccountInfo = _a.getAccountInfo, googleAuth = _a.googleAuth, updateCommunityToken = _a.updateCommunityToken, communityLogout = _a.communityLogout;
export var selectIsAutorized = (_b = accountSlice.selectors, _b.selectIsAutorized), selectIsLoading = _b.selectIsLoading, selectMyId = _b.selectMyId, selectMyRole = _b.selectMyRole;
export default accountSlice.reducer;
