import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
    const currentLocation = useLocation();
    const prevRef = React.useRef({ location: currentLocation });

    useEffect(() => {
        const isOnlyOneScreen = !!(currentLocation.state && currentLocation.state.onlyOneScreen);
        const isPopcardLead = currentLocation?.search?.includes('popcardLead');
        if (currentLocation !== prevRef?.current?.location && !isOnlyOneScreen && !isPopcardLead) {
            const userAgent = window.navigator.userAgent;
            const isSafari = userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1;
            if (isSafari || userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
                window.parent.postMessage('safariScrollToTop', '*');
            }
            // scrollIntoView fix for iPhone/iPad/Safari

            document.getElementById('tap-page').scrollIntoView({ behavior: 'smooth' });
            // scrollIntoView.options not supported in old Chrome and IE - added polyfill
            prevRef.current = {
                location: currentLocation,
            };
        }
    }, [currentLocation]);

    return children;
};

export default ScrollToTop;
