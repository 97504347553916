import queryString from 'query-string';
import React from 'react';
import AppendHead from 'react-append-head';
import TagManager from 'react-gtm-module';
import { getUniversityGroupId, getUniversityIdNew } from '../helpers/Helpers';

const query = queryString.parse(window.location.href.split('?')[1]);
const customGtm = query.customGtm ? query.customGtm : null;

export const initializeGtm = () => {
    window.addEventListener('load', () => {
        const fromDashboard = window.location.href.includes('?tap-dashboard=true');
        if (fromDashboard) {
            return;
        }
        setTimeout(() => {
            let gtmId;

            if (customGtm) {
                // If a custom GTM ID is passed in the data-gtm attribute of the iframe or customGtm query param
                gtmId = customGtm.toUpperCase();
            } else {
                const universityId = getUniversityIdNew();

                switch (universityId) {
                    // Don't add more customer Google Tag Manager IDs here! Direct them towards the data-gtm attribute in their iframe embed
                    case '31':
                        gtmId = 'GTM-T9N7FP'; // UEA - Students
                        break;

                    case '189':
                        gtmId = 'GTM-PX73QR'; // UTS
                        break;

                    case '315':
                        gtmId = 'GTM-NC7M38Q'; // Queensland
                        break;

                    case '390':
                        gtmId = 'GTM-THCKZDF'; // Leeds
                        break;

                    case '406':
                        gtmId = 'GTM-TNRVD4'; // Victoria University
                        break;

                    case '100061': // Test Uni
                        gtmId = 'GTM-PH5MW8J7';
                        break;

                    case '1474': // Cyclops
                        gtmId = 'GTM-MSZHBVX';
                        break;

                    default:
                        gtmId = null; // "GTM-N8ZHVLJ"; // fall back to standard TAP GTM container
                }
            }

            const data = {};
            if (window.groupId) {
                data.groupId = window.groupId;
            } else {
                data.universityId = window.universityId;
            }

            if (gtmId) {
                TagManager.initialize({ gtmId, dataLayer: data });
            }
        }, 100);
    });
};

// eslint-disable-next-function
export const MatomoScript = () => {
    return (
        <AppendHead>
            <script type="text/javascript">
                var _paq = window._paq = window._paq || []; /* tracker methods like "setCustomDimension" should be
                called before "trackPageView" */ _paq.push(['trackPageView']); _paq.push(['enableLinkTracking']);
                {(function () {
                    var _paq = (window._paq = window._paq || []);
                    var u = 'https://theambassadorplatform.matomo.cloud/';
                    _paq.push(['setTrackerUrl', u + 'matomo.php']);
                    _paq.push(['setSiteId', '2']);
                    var d = document,
                        g = d.createElement('script'),
                        s = d.getElementsByTagName('script')[0];
                    g.async = true;
                    g.src = 'https://cdn.matomo.cloud/theambassadorplatform.matomo.cloud/matomo.js';
                    s.parentNode.insertBefore(g, s);
                })()}
            </script>
        </AppendHead>
    );
};

// TODO: let's get all this in the dashboard so admins can add custom snippets to <head>

const TapPageVarSnippets = () => {
    const universityId = getUniversityIdNew();

    switch (universityId) {
        case '86': // Test Uni
        case '249': // University of Sydney - Adobe pixel
            return (
                <AppendHead>
                    <script
                        src="//assets.adobedtm.com/84fa4ed6cd2d/93f43475a61e/launch-a9ab0e6066cc.min.js"
                        async="async"
                    ></script>
                </AppendHead>
            );

        case '100061': // Test Uni
        case '331': // La Trobe University - Adobe pixel
            return (
                <AppendHead>
                    <script
                        src="//assets.adobedtm.com/launch-EN1a149531c790462ba2045950e23a51d1.min.js"
                        async="async"
                    ></script>
                </AppendHead>
            );
    }

    const groupId = getUniversityGroupId();

    switch (groupId) {
        case '40': // THE
        case '42': // THE Test
            return (
                <AppendHead>
                    <script src="/js/fb-pixel-the.js" async="async"></script>
                </AppendHead>
            );
    }

    return null;
};

export default TapPageVarSnippets;
