import { useMemo } from 'react';
import { useParams } from 'react-router';
// TODO: temporary solution, need to replace with provider os some kind of store
export function useSanitizedParams() {
    var params = useParams();
    if (!params.universityId) {
        throw new Error('universityId is required');
    }
    var sanitizedParams = useMemo(function () {
        return {
            universityId: Number(params.universityId),
            slug: params.slug,
        };
    }, [params.universityId, params.slug]);
    return sanitizedParams;
}
