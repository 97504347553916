var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { useSanitizedParams } from 'src/app/community/hooks/useSanitizedParams';
import { axiosBaseQuery } from 'src/system/query/axiosQuery';
// TODO Reset state on logout
export var widgetApi = createApi({
    reducerPath: 'widgetApi',
    baseQuery: axiosBaseQuery({ baseUrl: '/widget' }),
    refetchOnReconnect: true,
    tagTypes: ['Widget'],
    endpoints: function (builder) { return ({
        getWidgetInfo: builder.query({
            query: function (universityId) { return ({
                url: '/settings',
                params: { universityId: universityId },
            }); },
            providesTags: function (_, __, id) { return [{ type: 'Widget', id: id }]; },
            transformResponse: function (response) {
                var _a;
                return __assign(__assign({}, response), { isTestUniversity: response.is_test_university, showNumberLikesViews: (_a = response.show_number_likes_views) !== null && _a !== void 0 ? _a : false });
            },
        }),
    }); },
});
export var useGetWidgetInfoQuery = widgetApi.useGetWidgetInfoQuery;
export function useSelectWidgetInfo(selector) {
    var universityId = useSanitizedParams().universityId;
    var data = useGetWidgetInfoQuery(universityId).data;
    return data ? selector(data) : undefined;
}
export var selectUniversity = function (state) { return state.institution; };
