/**
 * Configuration object for the application.
 *
 * @typedef {Object} AppConfig
 * @property {string} liveSwitch - The URL for the live switch.
 * @property {string} liveSwitchAppId - The application ID for live switch.
 * @property {string} api_host_clear - The clear API host URL.
 * @property {string} dashboard_url - The URL for the dashboard.
 * @property {string} api_host - The API host URL.
 * @property {string} pusher_key - The key for Pusher integration.
 * @property {string} proxy_ga_url - The URL for the GA proxy.
 * @property {string} tapPageUrl - The URL for the Tap page.
 * @property {Object} ssoAuthData - Single Sign-On authentication data.
 * @property {Object} ssoAuthData.auth0 - Auth0 authentication data.
 * @property {string} ssoAuthData.auth0.domain - Auth0 domain.
 * @property {string} google_id - Google client ID.
 * @property {string} facebook_id - Facebook app ID.
 * @property {string} tik_tok_id - TikTok app ID.
 */

/**
 * Application configuration.
 *
 * @type {AppConfig}
 */
const config = {
    liveSwitch: 'https://cloud.liveSwitch.io/',
    liveSwitchAppId: '75dce1ef-cc54-4558-8c76-5a7c6f0738e8',
};

if (!process.env.APP_ENV || process.env.APP_ENV === 'development') {
    // Development environment settings
    config.api_host_clear = 'https://api-dev.tap.st/';
    config.dashboard_url = 'https://d17w1svzx7iihh.cloudfront.net/#/';
    config.api_host = config.api_host_clear + 'v1';
    config.pusher_key = 'b3ed46dc4b4ff9192bef';
    config.proxy_ga_url = 'https://proxy.tap.tf/';
    config.tapPageUrl = 'https://tappage-dev.tap.st/';
    config.ssoAuthData = {
        auth0: {
            domain: 'theambassadorplatform-dev.eu.auth0.com',
        },
    };
} else if (!process.env.APP_ENV || process.env.APP_ENV === 'staging') {
    // Staging environment settings
    config.api_host_clear = 'https://api-staging.tap.st/';
    config.dashboard_url = 'https://d210wul5r7tv4.cloudfront.net/#/';
    config.api_host = config.api_host_clear + 'v1';
    config.pusher_key = '5db5ca19614e8fbc4515';
    config.proxy_ga_url = 'https://proxy.theambassadorplatform.com/';
    config.tapPageUrl = 'https://tappage-staging.tap.st/';
    config.ssoAuthData = {
        auth0: {
            domain: 'theambassadorplatform-staging.eu.auth0.com',
        },
    };
} else if (!process.env.APP_ENV || process.env.APP_ENV === 'local') {
    config.api_host_clear = 'http://0.0.0.0:3333/';
    config.dashboard_url = 'https://d210wul5r7tv4.cloudfront.net/#/';
    config.api_host = config.api_host_clear + 'v1';
    config.pusher_key = '5db5ca19614e8fbc4515';
    config.proxy_ga_url = 'https://proxy.theambassadorplatform.com/';
    config.tapPageUrl = 'https://tappage-staging.tap.st/';
    config.ssoAuthData = {
        auth0: {
            domain: 'theambassadorplatform-staging.eu.auth0.com',
        },
    };
} else {
    // Production environment settings
    config.api_host_clear = 'https://api.theaccessplatform.com/';
    config.dashboard_url = 'https://dashboard.theambassadorplatform.com/#/';
    config.api_host = config.api_host_clear + 'v1';
    config.pusher_key = 'b467b31f50874ddbe4a1';
    config.proxy_ga_url = 'https://proxy.theambassadorplatform.com/';
    config.tapPageUrl = 'https://tappage.theaccessplatform.com/';
    config.ssoAuthData = {
        auth0: {
            domain: 'theambassadorplatform.eu.auth0.com',
        },
    };
}

// Additional settings
config.google_id = '863630181511-3vst45jvtq3s626drvoo0l0eur4hb6d3.apps.googleusercontent.com';
config.facebook_id = '2689699254580113';
config.tik_tok_id = 'awdrx0z2u8se3nor';

export default config;
