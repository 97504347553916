var _a;
import { configureStore } from '@reduxjs/toolkit';
import Notifications from '../../modules/react-notification-system-redux';
import communityAccountReducer from '../slices/account';
import subjectsReducer from '../slices/subjects';
import { widgetApi } from '../slices/widget/query';
export var communityStore = configureStore({
    reducer: (_a = {
            notifications: Notifications.reducer,
            subjects: subjectsReducer,
            communityAccount: communityAccountReducer
        },
        _a[widgetApi.reducerPath] = widgetApi.reducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: false,
        }).concat(widgetApi.middleware);
    },
});
