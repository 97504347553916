import { App as AntApp } from 'antd';
import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './app/ScrollToTop';
import { initializeGtm } from './components/TapPageVarSnippets';
import { isCommunity } from './helpers/is-community';

initializeGtm();
const App = React.lazy(() => import('./app/App'));
const CommunityApp = React.lazy(() => import('./app/CommunityApp'));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <AntApp>
        {isCommunity() ? (
            <CommunityApp />
        ) : (
            <BrowserRouter basename="/">
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </BrowserRouter>
        )}
    </AntApp>
);
