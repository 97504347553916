import HttpService from '../../system/HttpServices';
import pusher from '../../system/services/PusherWrapper';

export default {
    get: () => {
        return HttpService.get({ path: '/dialogs', requestType: 'interaction' });
    },
    groupDialogs: (universityIds, dialogId) => {
        return HttpService.post({
            path: '/dialogs/groupChats',
            data: { universityIds, type: 4, dialogId },
            requestType: 'interaction',
        });
    },
    groupDialogsOpened: (universityIds, dialogId) => {
        return HttpService.post({
            path: '/dialogs/groupChatsOpened',
            data: { universityIds, type: 4, dialogId },
            requestType: 'interaction',
        });
    },
    prospectDialogs: (universityIds, dialogId) => {
        return HttpService.post({
            path: '/dialogs/prospectDialogs',
            data: { universityIds, dialogId },
            requestType: 'interaction',
        });
    },
    ambassadorDialogs: (dialogId) => {
        return HttpService.get({ path: '/dialogs/ambassadorDialogs', data: { dialogId }, requestType: 'interaction' });
    },
    getById: (dialog_id, privateCode) => {
        return HttpService.get({
            path: '/dialogs/' + dialog_id,
            data: {
                privateCode,
            },
            requestType: 'interaction',
        });
    },
    getDialogsIds: (universityIds) => {
        return HttpService.get({
            path: '/dialogs/ids',
            data: {
                universityIds,
                isActual: true, // dialogs that have the last activity >= 3 months ago
            },
            requestType: 'interaction',
        });
    },
    getGroupChatById: (dialog_id, privateCode) => {
        return HttpService.get({
            path: '/dialogs/groupChat/' + dialog_id,
            data: {
                privateCode,
            },
            requestType: 'interaction',
        });
    },
    flag: (dialog_id, data) => {
        data.socketId = pusher.connection.socket_id;
        return HttpService.put({ path: '/dialogs/' + dialog_id + '/report', data, requestType: 'interaction' });
    },
    closeToggle: (dialog_id) => {
        return HttpService.put({
            path: '/dialogs/' + dialog_id + '/close/toggle',
            data: {
                socketId: pusher.connection.socket_id,
            },
            requestType: 'interaction',
        });
    },
    closeAmbassadorToggle: (dialog_id) => {
        return HttpService.put({
            path: '/dialogs/' + dialog_id + '/close/ambassadorToggle',
            data: {
                socketId: pusher.connection.socket_id,
            },
            requestType: 'interaction',
        });
    },
    findOrCreate: (student_id, groupId) => {
        const data = {};
        if (groupId) {
            data['groupId'] = groupId;
        }
        return HttpService.post({ path: '/dialogs/' + student_id + '/findOrCreate', data, requestType: 'interaction' });
    },
    afterDialogReport: (dialog_id, data) => {
        return HttpService.put({
            path: '/dialogs/' + dialog_id + '/afteDialogReport',
            data,
            requestType: 'interaction',
        });
    },
    sendFeedback: (data) => {
        return HttpService.put({ path: '/dialogs/sendFeedback', data, requestType: 'interaction' });
    },
    sendProspectFeedback: (dialogId, data) => {
        return HttpService.post({
            path: '/dialogs/' + dialogId + '/sendProspectFeedback',
            data,
            requestType: 'interaction',
        });
    },
    inviteAmbassadors: (users, dialog_id) => {
        return HttpService.put({
            path: '/dialogs/' + dialog_id + '/inviteAmbassadors',
            data: {
                users,
                socketId: pusher.connection.socket_id,
            },
            requestType: 'interaction',
        });
    },
    inviteAdmins: (users, dialog_id) => {
        return HttpService.put({
            path: '/dialogs/' + dialog_id + '/inviteAdmins',
            data: {
                users,
                socketId: pusher.connection.socket_id,
            },
            requestType: 'interaction',
        });
    },
    newMessagesCounter: (universityIds) => {
        return HttpService.get({
            path: '/dialogs/new/messagesCounter',
            data: { universityIds },
            requestType: 'interaction',
        });
    },
    newChatsMessages: (universityIds) => {
        return HttpService.get({
            path: '/dialogs/new/chatBoxDialogsMessages',
            data: { universityIds },
            requestType: 'interaction',
        });
    },
    leaveChat: (dialogId) => {
        return HttpService.put({
            path: '/dialogs/' + dialogId + '/exit',
            data: {
                socketId: pusher.connection.socket_id,
            },
            requestType: 'interaction',
        });
    },
    getProspectInfo: (prospectId) => {
        return HttpService.get({ path: '/dialogs/prospectInfo', data: prospectId, requestType: 'interaction' });
    },
    chatToProspect: ({ prospectId }) => {
        return HttpService.post({
            path: '/dialogs/createChat',
            data: { userId: prospectId },
            requestType: 'interaction',
        });
    },
};
