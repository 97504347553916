import HttpService from '../../system/HttpServices';

export default {
    get: (universityId = null, groupId = null, prefilterItems = null, contentTypes = null) => {
        let error = null;
        if (prefilterItems?.length > 10) {
            error = 'prefilter';
        }
        return HttpService.get({
            path: '/settings',
            data: {
                universityId,
                groupId,
                prefilterItems,
                contentTypes,
            },
            typeError: error,
        });
    },
    getSocialProofInfo: (universityId = null) => {
        return HttpService.get({ path: '/social/proof', data: { universityId } });
    },
    getExperienceBasedQuestions: () => {
        return HttpService.get({ path: '/experienceBasedQuestionsAnswers' });
    },
};
