var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getUserFingerprint } from 'src/helpers/fingerprint';
import { httpServices } from '../../system/HttpServices/HttpService';
export default {
    checkProspectEmail: function (data) {
        return httpServices.post({
            path: '/auth/checkEmail',
            data: data,
            withCredentials: true,
            requestType: 'community',
        });
    },
    getSubjects: function (data) {
        return httpServices.get({
            path: '/data/subjects',
            data: data,
            withCredentials: true,
            requestType: 'common',
        });
    },
    register: function (data) {
        return httpServices.post({
            path: '/auth/registration',
            data: __assign(__assign({}, data), { fingerprint: getUserFingerprint() }),
            withCredentials: true,
            requestType: 'community',
        });
    },
    login: function (data) {
        return httpServices.post({
            path: '/auth/login',
            data: __assign(__assign({}, data), { fingerprint: getUserFingerprint() }),
            withCredentials: true,
            requestType: 'community',
        });
    },
    googleAuth: function (data) {
        return httpServices.post({
            path: '/auth/google',
            data: __assign(__assign({}, data), { fingerprint: getUserFingerprint() }),
            withCredentials: true,
            requestType: 'community',
        });
    },
    getAccounItnfo: function (data) {
        return httpServices.get({
            path: '/account/info',
            data: data,
            requestType: 'community',
        });
    },
    updateAccounItnfo: function (data) {
        return httpServices.put({
            path: '/account/profile',
            data: data,
            withCredentials: true,
            requestType: 'community',
        });
    },
    recoveryPassword: function (data) {
        return httpServices.post({
            path: '/auth/recovery/password',
            data: data,
            requestType: 'community',
        });
    },
    confirmPassword: function (data) {
        return httpServices.post({
            path: '/auth/recovery/passwordConfirm',
            data: data,
            withCredentials: true,
            requestType: 'community',
        });
    },
};
